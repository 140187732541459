import { useState } from "react";
import {
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import { auth } from "./firebase";
import { useNavigate } from "react-router-dom";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const signInHandler = (e) => {
    e.preventDefault();
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        console.log(userCredential);
      })
      .catch((error) => {
        console.log(error);
      });
    navigate("/");
  };

  const googleHandler = async (e) => {
    e.preventDefault();
    try {
      const provider = new GoogleAuthProvider();
      const userCredential = await signInWithPopup(auth, provider);
      console.log(userCredential);
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <div className="container-fluid h-120">
      <div className="row justify-content-center align-items-center h-100">
        <div className="col-12">
          <div
            className="card bg-white my-5 mx-auto"
            style={{ borderRadius: "1rem", maxWidth: "500px" }}
          >
            <div className="card-body p-5 d-flex flex-column">
              <h2 className="fw-bold mb-2 text-center">Giriş Yap</h2>

              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  className="form-control mb-4"
                  id="formControlLg"
                  type="email"
                  size="lg"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>

              <div className="form-group">
                <label htmlFor="password">Şifre</label>
                <input
                  className="form-control mb-4"
                  id="formControlLg"
                  type="password"
                  size="lg"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>

              <div className="form-check mb-4">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="flexCheckDefault"
                />
                <label className="form-check-label" htmlFor="flexCheckDefault">
                  Beni Hatırla!
                </label>
              </div>

              <button
                onClick={signInHandler}
                type="submit"
                className="btn btn-lg btn-primary mb-2"
              >
                Giriş Yap
              </button>

              <hr className="my-4" />

              <button
                onClick={googleHandler}
                type="submit"
                className="btn btn-lg btn-danger mb-2 w-100"
              >
                <i className="fab fa-google mr-2"></i>
                Google ile Giriş
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
