import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getDatabase } from "firebase/database";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: "AIzaSyABTUPdiOvtmZuJWTbioxlM2e9KEdXh5MU",
  authDomain: "avci-dedektor-83c42.firebaseapp.com",
  databaseURL:
    "https://avci-dedektor-83c42-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "avci-dedektor-83c42",
  storageBucket: "avci-dedektor-83c42.appspot.com",
  messagingSenderId: "1083524954510",
  appId: "1:1083524954510:web:b93a231ea570f110f48cdf",
  measurementId: "G-M1GV7ZQXT5",
};

// Initialize Firebase
const initializedApp = initializeApp(firebaseConfig);

// Initialize Auth before accessing auth service
export const auth = getAuth(initializedApp);

const storage = getStorage(initializedApp);
const database = getDatabase(initializedApp);

export { initializedApp, storage, database };

const Logoff = () => {
  const handleSignout = () => {
    auth.signOut(); // Use the modular auth from getAuth
  };
  return <button onClick={handleSignout}>Çıkış yap</button>;
};

export default Logoff;
