import classes from "./TestVideos.module.css";
import { useState, useEffect } from "react";
import useAuth from "../hooks/useAuth";
import { firebaseConfig } from "../components/login/firebase";
import { initializeApp } from "firebase/app";
import { ref, set, getDatabase } from "firebase/database";

const API_URL =
  "https://avci-dedektor-83c42-default-rtdb.europe-west1.firebasedatabase.app/videos.json";

const TestVideos = () => {
  const user = useAuth();
  const [videos, setVideos] = useState([]);
  const firebaseApp = initializeApp(firebaseConfig);

  const database = getDatabase(firebaseApp);

  const fetchData = async () => {
    try {
      const response = await fetch(API_URL);

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const data = await response.json();
      const videosArray = Object.entries(data).map(([id, values]) => ({
        id,
        ...values,
      }));
      setVideos(videosArray);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const deleteHandler = async (videoId) => {
    /*try {
      const apiUrl = `https://avci-dedektor-83c42-default-rtdb.europe-west1.firebasedatabase.app/${videoId}.json`;
      const response = await fetch(apiUrl, { method: "DELETE" });

      if (response.ok) {
        setVideos((prevVideos) =>
          prevVideos.filter((video) => video.id !== videoId)
        );
      } else {
        console.error("Failed to delete video");
      }
    } catch (error) {
      console.error("Error deleting:", error);
    }*/

    try {
      // Set the value to null to delete the data at the specified key
      await set(ref(database, `videos`), null);
      console.log("Data deleted successfully");
      // Handle success or update state as needed
    } catch (error) {
      console.error("Error deleting data:", error);
      // Handle error as needed
    }
    window.location.reload();
  };

  const VideoTag = () =>
    videos.map((video, index) => (
      <li key={index}>
        <h2>{video.titleValue}</h2>
        <iframe
          className={classes.video}
          src={`https://www.youtube.com/embed/${
            video.linkValue?.match(/[?&]v=([^?&]+)/)?.[1]
          }`}
          title="YouTube Video"
          allowFullScreen
        />
        {user && (
          <button
            onClick={() => deleteHandler(video.id)}
            className="btn btn-danger"
          >
            Delete
          </button>
        )}
      </li>
    ));

  return videos.length !== 0 ? (
    <div className={classes.videosWrapper}>
      <ul>
        <VideoTag />
      </ul>
    </div>
  ) : (
    <p
      style={{
        fontSize: "5rem",
        position: "absolute",
        top: "35%",
        left: "37%",
      }}
    >
      No videos found!
    </p>
  );
};

export default TestVideos;
