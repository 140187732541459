import classes from "./AddressPage.module.css";

const Address = (props) => {
  return (
    <div className={classes.addressWrapper}>
      <div className={classes.addressContainer}>
        <h1>ADRESİMİZ</h1>
        <address>
          Ünalan, 2. Bostancı Sk. No:10 D:1, 64100 Uşak Merkez/Uşak, Türkiye
        </address>
      </div>
      <div className={classes.mapContainer}>
        <iframe
          src="https://www.google.com/maps/d/embed?mid=1HMlLZVZ2uJnjNnjx326OZD19f7gpl3M&ehbc=2E312F"
          title="address"
        ></iframe>
      </div>
    </div>
  );
};

export default Address;
