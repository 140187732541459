import classes from "./Announcements.module.css";
import useAuth from "../hooks/useAuth";
import { useState, useEffect } from "react";
import { listAll, ref, deleteObject, getDownloadURL } from "firebase/storage";
import { storage } from "../components/login/firebase";
import styled, { keyframes } from "styled-components";
import _ from "lodash";
import { ref as rf, set, getDatabase } from "firebase/database";
import { firebaseConfig } from "../components/login/firebase";
import { initializeApp } from "firebase/app";

const API_URL =
  "https://avci-dedektor-83c42-default-rtdb.europe-west1.firebasedatabase.app/announcements.json";

export const Announcement = (
  imageContainerClass,
  titleContainerClass,
  priceContainerClass,
  showDeleteButton = true
) => {
  const firebaseApp = initializeApp(firebaseConfig);

  const database = getDatabase(firebaseApp);

  const user = useAuth();
  const [announcements, setAnnouncements] = useState([]);
  const [imagesList, setImagesList] = useState([]);
  const [keyValues, setKeyValues] = useState([]);

  const [loading, setLoading] = useState(false);
  const imageListRef = ref(storage, "announcementImages/");
  const rotate = keyframes`
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  `;
  const Spinner = styled.div`
    border: 8px solid #f3f3f3;
    border-top: 8px solid #3498db;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: ${rotate} 1s linear infinite;
  `;

  const fetchImages = async () => {
    setLoading(true);

    try {
      const imageUrls = await Promise.all(
        keyValues.map(async (keyValue) => {
          const path = `announcementImages/${keyValue}/`;
          const imageListRef = ref(storage, path);
          const imageList = await listAll(imageListRef);

          if (imageList.items.length > 0) {
            const imageUrl = await getDownloadURL(imageList.items[0]);
            return imageUrl;
          }

          return null;
        })
      );

      setImagesList(imageUrls.filter((url) => url !== null));
    } catch (error) {
      console.error("Error fetching images:", error);
    }
    setLoading(false);
  };
  const debouncedFetchImages = _.debounce(fetchImages, 300);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch(API_URL);

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const data = await response.json();
        const announcementsArray = Object.entries(data).map(([id, values]) => ({
          id,
          ...values,
        }));
        setAnnouncements(announcementsArray);
        const keyValueArray = announcementsArray.map(
          (product) => product.keyValue
        );
        setKeyValues(keyValueArray);
      } catch (error) {
        console.error("Error:", error);
      }
      setLoading(false);
    };

    fetchData();
  }, []);

  useEffect(() => {
    debouncedFetchImages();
  }, [keyValues]);

  const deleteImage = async (imageUrl) => {
    const imageRef = ref(storage, imageUrl);
    await deleteObject(imageRef);
  };

  const deleteHandler = async (announcementId, images) => {
    try {
      // Delete the announcement from Firebase Realtime Database
      await set(rf(database, `announcements/${announcementId}`), null);
      console.log("Data deleted successfully");

      const keyValue = announcements.map(
        (announcement) => announcement.keyValue
      );

      // Get a list of all items in the directory
      const imageRef = ref(storage, `announcementImages/${keyValue}`);
      const items = await listAll(imageRef);

      // Process each file and delete it
      await Promise.all(
        items.items.map(async (item) => {
          await deleteObject(item);
        })
      );

      // Update the state to remove the deleted announcement
      // Reload the page
      window.location.reload();
    } catch (error) {
      console.error("Error deleting:", error);
    }
    window.location.reload();
  };

  if (loading) {
    return (
      <div style={{ position: "absolute", top: "50%", left: "50%" }}>
        <Spinner />
      </div>
    );
  }

  return announcements.map((announcement, index) => (
    <li key={announcement.id}>
      <div className={`${classes.imageContainer} ${imageContainerClass}`}>
        <img src={imagesList[index]} alt="announcement" />
      </div>
      <div className={classes.horizontalLineOne}></div>

      <div className={`${classes.titleContainer} ${titleContainerClass}`}>
        <h2>{announcement.titleValue}</h2>
      </div>
      <div className={classes.horizontalLineTwo}></div>

      <div className={`${classes.priceContainer} ${priceContainerClass}`}>
        <p>{announcement.desValue}</p>{" "}
      </div>
      {user && showDeleteButton && (
        <button
          onClick={() => deleteHandler(announcement.id, imagesList[index])}
          className="btn btn-danger"
          style={{ position: "absolute", bottom: "0" }}
        >
          Delete
        </button>
      )}
    </li>
  ));
};

const Announcements = () => {
  return (
    <div className={classes.announcementsWrapper}>
      <ul>
        <Announcement />
      </ul>
    </div>
  );
};

export default Announcements;
