import { useParams } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import { ref, getDownloadURL, list } from "firebase/storage";
import { storage } from "../components/login/firebase";
import classes from "./ProductDetail.module.css";
import { Link } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import { Product } from "./ProductsPage";
import useAuth from "../hooks/useAuth";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/database";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase, ref as dbRef, update } from "firebase/database";
import { firebaseConfig } from "../components/login/firebase";
import { motion, AnimatePresence } from "framer-motion";
import { faL } from "@fortawesome/free-solid-svg-icons";

const firebaseApp = initializeApp(firebaseConfig);
const database = getDatabase(firebaseApp);

const ProductDetail = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const { id } = useParams();
  const user = useAuth();
  const [product, setProduct] = useState(null);
  const [productImages, setProductImages] = useState([]);
  const [selectProduct, setSelectProduct] = useState(0);
  const [prevValue, setPrevValue] = useState(null);
  const [isPriceEditting, setIsPriceEditing] = useState(false);
  const [isTitleEditing, setIsTitleEditing] = useState(false);
  const [isDesEditing, setIsDesEditing] = useState(false);
  const [newTitle, setNewTitle] = useState("");
  const [newPrice, setNewPrice] = useState("");
  const [newDes, setNewDes] = useState("");
  const [isMouseDown, setIsMouseDown] = useState(false);

  const handleMouseDown = () => {
    setIsMouseDown(true);
  };

  const handleMouseUp = () => {
    setIsMouseDown(false);
  };

  const handleMouseLeave = () => {
    setIsMouseDown(false);
  };

  const handleMouseMove = (e) => {
    if (isMouseDown) {
      // Calculate the direction of movement
      const deltaX = e.movementX;

      // Adjust the current image index based on the movement
      setSelectProduct((prevImage) => {
        let newIndex = prevImage - Math.sign(deltaX);

        // Ensure the index stays within the bounds of the array
        if (newIndex < 0) {
          newIndex = productImages.length - 1;
        } else if (newIndex >= productImages.length) {
          newIndex = 0;
        }

        return newIndex;
      });
    }
  };

  const [isDownPriceEditting, setIsDownPriceEditing] = useState(false);
  const [newDownPrice, setDownNewPrice] = useState("");
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // Add event listener to update windowWidth on resize
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const productRef = dbRef(database, `products/${id}`);

  const editTitleHandler = () => {
    setIsTitleEditing(true);
  };

  const doneEditTitleHandler = async () => {
    try {
      await update(productRef, {
        titleValue: newTitle,
      });

      setIsTitleEditing(false);
    } catch (error) {}
  };

  const discardEditTitleHandler = () => {
    setIsTitleEditing(false);
  };

  const editPriceHandler = () => {
    setIsPriceEditing(true);
  };

  const doneEditPriceHandler = () => {
    try {
      /*   update(productRef, {
        priceValue: newPrice, //indirim var ise, indirimli price yerine, indirimli price yok ise, indirimli priceyi true yap, eski price yap, sonradanda yeni priceyi koy
      });*/
      update(productRef, {
        priceValue: newPrice,
      });

      setIsPriceEditing(false);
    } catch (error) {}
  };

  const editDownPriceHandler = () => {
    setIsDownPriceEditing(true);
  };

  const doneEditDownPriceHandler = () => {
    try {
      update(productRef, {
        downgradedPriceValue: newDownPrice,
      });

      setIsPriceEditing(false);
    } catch (error) {}
  };

  const discardEditPriceHandler = () => {
    setIsPriceEditing(false);
  };

  const editDesHandler = () => {
    setIsDesEditing(true);
  };

  const doneEditDesHandler = () => {
    update(productRef, {
      desValue: newDes,
    });

    setIsDesEditing(false);

    try {
      update(productRef, {
        desValue: newDes,
      });

      setIsDesEditing(false);
    } catch (error) {
      console.error("Error des title:", error);
    }
  };

  const discardEditDesHandler = () => {
    setIsDesEditing(false);
  };

  const desRef = useRef(null);

  const scrollHandler = () => {
    if (desRef.current) {
      desRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;
  const Spinner = styled.div`
    border: 8px solid #f3f3f3;
    border-top: 8px solid #3498db;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: ${rotate} 1s linear infinite;
  `;

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await fetch(
          `https://avci-dedektor-83c42-default-rtdb.europe-west1.firebasedatabase.app/products/${id}.json`
        );

        if (!response.ok) {
          throw new Error("Failed to fetch product");
        }

        const data = await response.json();
        setProduct(data);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchProduct();
  }, [id]);

  const keyValue = product ? product.keyValue : null;
  const path = `productImages/${keyValue}`;

  useEffect(() => {
    const fetchImages = async () => {
      if (product) {
        // Create the reference for the productImages folder
        const productImagesRef = ref(storage, path);

        try {
          const response = await list(productImagesRef);
          const imageUrls = await Promise.all(
            response.items.map(async (item) => {
              const url = await getDownloadURL(item);
              return url;
            })
          );

          setProductImages(imageUrls);
        } catch (error) {
          console.error("Error fetching images:", error);
        }
      }
    };

    fetchImages();
  }, [product]);

  const nullHandler = () => {
    setPrevValue(selectProduct);
    setSelectProduct(0);
  };
  const secondHandler = () => {
    setPrevValue(selectProduct);

    setSelectProduct(2);
  };
  const thirdHandler = () => {
    setPrevValue(selectProduct);

    setSelectProduct(3);
  };
  const fourthHandler = () => {
    setPrevValue(selectProduct);

    setSelectProduct(4);
  };
  const firstHandler = () => {
    setPrevValue(selectProduct);
    setSelectProduct(1);
  };

  console.log(prevValue);
  console.log(selectProduct);

  if (!product || !productImages) {
    return (
      <div style={{ position: "absolute", top: "50%", left: "50%" }}>
        <Spinner />
      </div>
    );
  }

  const EditButton = ({ content, onClick }) => {
    return (
      user && (
        <button onClick={onClick} className={classes.editButton}>
          {content}{" "}
        </button>
      )
    );
  };

  let initialCon;
  let exitCon;

  if (prevValue <= selectProduct) {
    initialCon = { opacity: 0.5, x: "10%", width: "100%" };
    exitCon = { opacity: 0.5, x: "-10%", width: "100%" };
  } else {
    initialCon = { opacity: 0.5, x: "-10%", width: "100%" };
    exitCon = { opacity: 0.5, x: "10%", width: "100%" };
  }

  return (
    <>
      {windowWidth > 1000 ? (
        <div className={classes.productDetailWrapper}>
          <div className={classes.imagesContainer}>
            <div className={classes.bigImageWrapper}>
              <motion.img
                key={selectProduct}
                alt={`Slide ${selectProduct}`}
                initial={initialCon}
                animate={{ opacity: 1, x: 0, width: "100%" }}
                exit={exitCon}
                transition={{ duration: 0.35, ease: "easeInOut" }}
                src={productImages[selectProduct]}
              />
            </div>
            <ul className={classes.smallImagesWrapper}>
              <li onClick={nullHandler}>
                <img
                  className={classes.smallImage}
                  src={productImages[0]}
                  alt={product.titleValue[0]}
                />
              </li>

              <li onClick={firstHandler}>
                <img
                  className={classes.smallImage}
                  src={productImages[1]}
                  alt={product.titleValue[0]}
                />
              </li>
              <li onClick={secondHandler}>
                <img
                  className={classes.smallImage}
                  src={productImages[2]}
                  alt={product.titleValue[0]}
                />
              </li>
              <li onClick={thirdHandler}>
                <img
                  className={classes.smallImage}
                  src={productImages[3]}
                  alt={product.titleValue[0]}
                />
              </li>
              <li onClick={fourthHandler}>
                <img
                  className={classes.smallImage}
                  src={productImages[4]}
                  alt={product.titleValue[0]}
                />
              </li>
            </ul>
            <div ref={desRef} className={classes.description}>
              {!isDesEditing && (
                <EditButton
                  onClick={editDesHandler}
                  content="Açıklamayı Düzenle"
                />
              )}
              <h2>Ürün Özellikleri</h2>
              {isDesEditing ? (
                <div className="form-group">
                  <textarea
                    placeholder="Yeni açıklama girin"
                    className="input"
                    type="text"
                    size="lg"
                    onChange={(e) => setNewDes(e.target.value)}
                  />
                  <EditButton onClick={doneEditDesHandler} content="Tamam" />
                  <EditButton
                    onClick={discardEditDesHandler}
                    content="Vazgeç"
                  />
                </div>
              ) : (
                <p>{product.desValue} </p>
              )}{" "}
            </div>{" "}
          </div>
          <div className={classes.announcementsWrapper}>
            {isTitleEditing ? (
              <div className="form-group">
                <label htmlFor="title">Başlık</label>
                <input
                  placeholder="Yeni bir başlık girin"
                  className="input"
                  type="text"
                  onChange={(e) => setNewTitle(e.target.value)}
                  size="lg"
                />
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <EditButton onClick={doneEditTitleHandler} content="Tamam" />
                  <EditButton
                    onClick={discardEditTitleHandler}
                    content="Vazgeç"
                  />
                </div>{" "}
              </div>
            ) : (
              <h1> {product.titleValue} </h1>
            )}
            {!isTitleEditing && (
              <EditButton
                onClick={editTitleHandler}
                content="Başlığı Düzenle"
              />
            )}
            {isPriceEditting || isDownPriceEditting ? (
              <div className="form-group">
                <label htmlFor="title">Fiyat</label>
                <input
                  placeholder="Yeni bir başlık girin"
                  className="input"
                  type="text"
                  size="lg"
                  onChange={(e) =>
                    (isPriceEditting && setNewPrice(e.target.value)) ||
                    (isDownPriceEditting && setDownNewPrice(e.target.value))
                  }
                />
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <EditButton
                    onClick={
                      isDownPriceEditting
                        ? doneEditDownPriceHandler
                        : doneEditPriceHandler
                    }
                    content="Tamam"
                  />
                  <EditButton
                    onClick={discardEditPriceHandler}
                    content="Vazgeç"
                    onChange={(e) => setNewPrice(e.target.value)}
                  />
                </div>
              </div>
            ) : product.isDowngraded ? (
              <>
                <div className={classes.prices}>
                  <p style={{ color: "black", textDecoration: "line-through" }}>
                    {product.priceValue}₺
                  </p>
                  <p style={{ color: "black" }}>₺</p>
                </div>
                {!isPriceEditting && (
                  <EditButton
                    onClick={editPriceHandler}
                    content="Fiyatı Düzenle"
                  />
                )}
                {!isDownPriceEditting && (
                  <EditButton
                    onClick={editDownPriceHandler}
                    content="İndirimli Fiyatı Düzenle"
                  />
                )}
              </>
            ) : (
              <>
                <div className={classes.prices}>
                  <p style={{ color: "black" }}>{product.priceValue}₺</p>
                </div>
                {!isPriceEditting && (
                  <EditButton
                    onClick={editPriceHandler}
                    content="Fiyatı Düzenle"
                  />
                )}
                {!isDownPriceEditting && (
                  <EditButton content="İndirimli Fiyatı Düzenle" />
                )}
              </>
            )}
            <Link className={classes.neredenAlabilirim} to="/contact">
              Nereden Alabilirim?
            </Link>{" "}
            <button
              onClick={scrollHandler}
              className={classes.neredenAlabilirim}
              to="/contact"
            >
              Ürünün açılamasını görmek için tıklayın
            </button>
            <ul className={classes.otherProducts}>
              <Product
                imageContainerClass={classes.customImageContainerStyles}
                titleContainerClass={classes.customTitleContainerStyles}
                priceContainerClass={classes.customPriceContainerStyles}
                product={product}
                showDeleteButton={false}
              />{" "}
            </ul>
          </div>
        </div>
      ) : (
        /* 751'den küçük */
        <div className={classes.productDetailWrapperW2}>
          <div className={classes.topWrapper}>
            <div className={classes.imagesContainer}>
              <div className={classes.bigImageWrapper}>
                <img src={productImages[selectProduct]} alt="Selected" />
              </div>

              <ul className={classes.smallImagesWrapper}>
                <li onClick={nullHandler}>
                  <img
                    className={classes.smallImage}
                    src={productImages[0]}
                    alt={product.titleValue[0]}
                  />
                </li>

                <li onClick={firstHandler}>
                  <img
                    className={classes.smallImage}
                    src={productImages[1]}
                    alt={product.titleValue[0]}
                  />
                </li>
                <li onClick={secondHandler}>
                  <img
                    className={classes.smallImage}
                    src={productImages[2]}
                    alt={product.titleValue[0]}
                  />
                </li>
                <li onClick={thirdHandler}>
                  <img
                    className={classes.smallImage}
                    src={productImages[3]}
                    alt={product.titleValue[0]}
                  />
                </li>
                <li onClick={fourthHandler}>
                  <img
                    className={classes.smallImage}
                    src={productImages[4]}
                    alt={product.titleValue[0]}
                  />
                </li>
              </ul>
              <div ref={desRef} className={classes.descriptionWiki}>
                {!isDesEditing && (
                  <EditButton
                    onClick={editDesHandler}
                    content="Açıklamayı Düzenle"
                  />
                )}
                <h2>Ürün Özellikleri</h2>
                {isDesEditing ? (
                  <div className="form-group">
                    <textarea
                      placeholder="Yeni açıklama girin"
                      className="input"
                      type="text"
                      size="lg"
                      onChange={(e) => setNewDes(e.target.value)}
                    />
                    <EditButton onClick={doneEditDesHandler} content="Tamam" />
                    <EditButton
                      onClick={discardEditDesHandler}
                      content="Vazgeç"
                    />
                  </div>
                ) : (
                  <p>{product.desValue} </p>
                )}{" "}
              </div>
            </div>
            <div className={classes.announcementsWrapper}>
              {isTitleEditing ? (
                <div className="form-group">
                  <label htmlFor="title">Başlık</label>
                  <input
                    placeholder="Yeni bir başlık girin"
                    className="input"
                    type="text"
                    onChange={(e) => setNewTitle(e.target.value)}
                    size="lg"
                  />
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <EditButton
                      onClick={doneEditTitleHandler}
                      content="Tamam"
                    />
                    <EditButton
                      onClick={discardEditTitleHandler}
                      content="Vazgeç"
                    />
                  </div>{" "}
                </div>
              ) : (
                <h1> {product.titleValue} </h1>
              )}
              {!isTitleEditing && (
                <EditButton
                  onClick={editTitleHandler}
                  content="Başlığı Düzenle"
                />
              )}
              {isPriceEditting || isDownPriceEditting ? (
                <div className="form-group">
                  <label htmlFor="title">Fiyat</label>
                  <input
                    placeholder="Yeni bir başlık girin"
                    className="input"
                    type="text"
                    size="lg"
                    onChange={(e) =>
                      (isPriceEditting && setNewPrice(e.target.value)) ||
                      (isDownPriceEditting && setDownNewPrice(e.target.value))
                    }
                  />
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <EditButton
                      onClick={
                        isDownPriceEditting
                          ? doneEditDownPriceHandler
                          : doneEditPriceHandler
                      }
                      content="Tamam"
                    />
                    <EditButton
                      onClick={discardEditPriceHandler}
                      content="Vazgeç"
                      onChange={(e) => setNewPrice(e.target.value)}
                    />
                  </div>
                </div>
              ) : product.isDowngraded ? (
                <>
                  <div className={classes.prices}>
                    <p
                      style={{ color: "black", textDecoration: "line-through" }}
                    >
                      {product.priceValue}₺
                    </p>
                    &nbsp;&nbsp;
                    <p style={{ color: "black" }}>
                      {product.downgradedPriceValue}₺
                    </p>
                  </div>
                  {!isPriceEditting && (
                    <EditButton
                      onClick={editPriceHandler}
                      content="Fiyatı Düzenle"
                    />
                  )}
                  {!isDownPriceEditting && (
                    <EditButton
                      onClick={editDownPriceHandler}
                      content="İndirimli Fiyatı Düzenle"
                    />
                  )}
                </>
              ) : (
                <>
                  <div className={classes.prices}>
                    <p style={{ color: "black" }}>{product.priceValue}₺</p>
                  </div>
                  {!isPriceEditting && (
                    <EditButton
                      onClick={editPriceHandler}
                      content="Fiyatı Düzenle"
                    />
                  )}
                  {!isDownPriceEditting && (
                    <EditButton content="İndirimli Fiyatı Düzenle" />
                  )}
                </>
              )}
              <Link className={classes.neredenAlabilirimWiki} to="/contact">
                Nereden Alabilirim?
              </Link>{" "}
              <button
                onClick={scrollHandler}
                className={classes.neredenAlabilirimWiki}
                to="/contact"
              >
                Ürünün açılamasını görmek için tıklayın
              </button>{" "}
            </div>
          </div>
        </div>
      )}{" "}
    </>
  );
};

export default ProductDetail;
