import Navbar from "./navigation/Navbar";
import Footer from "./footer/Footer";
import { Outlet } from "react-router-dom";
import classes from "./Root.module.css"; // Import a separate CSS file for styling

const RootLayout = () => {
  return (
    <div className={classes.rootLayoutContainer}>
      <div className={classes.nav}>
        <Navbar />
      </div>
      <div className={classes.outlet}>
        <Outlet />
      </div>
      <div className={classes.footer}>
        <Footer />
      </div>
    </div>
  );
};

export default RootLayout;
