import { Link } from "react-router-dom";
import classes from "./Footer.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faWhatsapp,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

const DUMMY_FOOTER = `
Bu web sitesinin içeriği, tasarımı, metinleri, grafikleri, resimleri, kodları ve diğer materyalleri ile ilgili tüm haklar saklıdır. Bu materyaller, telif hakkı ve diğer fikri mülkiyet yasaları tarafından korunmaktadır. Bu materyaller, izin almadan kopyalanamaz, değiştirilemez, çoğaltılamaz, yayılamaz veya başka bir şekilde kullanılamaz.

Herhangi bir izinsiz kullanım veya telif hakkı ihlali, hukuki sorumluluğa neden olabilir. Telif hakkı sahibi olmayanların bu materyalleri kullanması yasaktır.

Tüm sorular ve izin talepleri için lütfen İletişim Bilgileri bölümündeki bilgileri kullanarak bize ulaşın.
`;

const Footer = () => {
  const currentDate = new Date();

  const currentYear = currentDate.getFullYear();
  return (
    <>
      <div className={classes.footerWrapper}>
        <div className={classes.textContainer}>
          <p>
            {" "}
            Tüm Hakları Saklıdır © {currentYear} <br />
            {DUMMY_FOOTER}{" "}
          </p>
        </div>
      </div>
      <div className={classes.linkContainer}>
        <Link
          className={classes.instagram}
          to="https://www.instagram.com/engindedektor/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon className={classes.icon} icon={faInstagram} />
        </Link>
        <Link
          className={classes.youtube}
          to="https://youtube.com/@AVCI-DEDEKTOR?si=oscGWkG6Jezg0bLO"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon className={classes.icon} icon={faYoutube} />
        </Link>
        <Link
          className={classes.facebook}
          to="https://www.facebook.com/engin.dedektorteknolojileri?mibextid=ZbWKwL"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon className={classes.icon} icon={faFacebook} />
        </Link>
        <Link
          className={classes.whatsapp}
          to="https://wa.me/+9005527117164"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon className={classes.icon} icon={faWhatsapp} />
        </Link>
      </div>
    </>
  );
};

export default Footer;
