import { useState } from "react";

const EditProduct = () => {
  const [isDiscount, setIsDiscount] = useState(false);
  const [isTitle, setIsTitle] = useState(false);
  const [isDes, setIsDes] = useState(false);

  return (
    <div className="container-fluid h-120">
      <div className="row justify-content-center align-items-center h-100">
        <div className="col-12">
          <div
            className="card bg-white my-5 mx-auto"
            style={{ borderRadius: "1rem", maxWidth: "500px" }}
          >
            <div className="card-body p-5 d-flex flex-column">
              <h2 className="fw-bold mb-2 text-center">Ürünü Güncelle</h2>
              <div className="form-group">
                <label htmlFor="title">Yeni Başlık Gir</label>
                <input
                  placeholder="Ürününüzün başlığını girin"
                  className="input"
                  type="text"
                  size="lg"
                />
              </div>

              <div className="form-group">
                <label htmlFor="checkbox">Yeni Fiyat Gir</label> <br />
                <input type="checkbox" size="lg" />
              </div>

              <div className="form-group">
                <label htmlFor="link">Yeni Açıklama Gir</label>
                <textarea className="input" type="text" size="lg" />
              </div>
              <button type="post" className="btn btn-lg btn-primary mb-2">
                Paylaş
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditProduct;
