import { RouterProvider, createBrowserRouter } from "react-router-dom";

import HomePage from "./pages/HomePage";
import RootLayout from "./components/Root";
import Address from "./pages/AddressPage";
import Contact from "./pages/ContactPage";
import GarantiBilgileri from "./pages/GarantiBilgileri";
import YasalZorunluluklar from "./pages/YasalZorunluluklar";
import About from "./pages/AboutPage";
import TestVideos from "./pages/TestVideos";
import Announcements from "./pages/AnnouncementsPage";
import Products from "./pages/ProductsPage";
import ProductDetail from "./pages/ProductDetail";
import Login from "./components/login/LoginPage";
import Card from "./components/card/Card";
import useAuth from "./hooks/useAuth";
import SharePage from "./pages/SharePage";
import ShareVideo from "./components/shareComponents/ShareVideo";
import ShareAnnouncement from "./components/shareComponents/ShareAnnouncement";
import ShareProduct from "./components/shareComponents/ShareProduct";
import ImageInputModal from "./components/contact-modal/ImageInputModal";
import EditProduct from "./components/shareComponents/EditProduct";
import ShareHomeVideo from "./components/shareComponents/ShareHomeVideo";
import ShareAbout from "./components/shareComponents/ShareAbout";
import ShareGaranti from "./components/shareComponents/ShareGaranti";
import ShareYasal from "./components/shareComponents/ShareYasal";

function App() {
  const user = useAuth();
  const router = createBrowserRouter([
    {
      path: "/",
      element: <RootLayout />,
      children: [
        { index: true, element: <HomePage /> },
        { path: "address", element: <Address /> },
        { path: "contact", element: <Contact /> },
        { path: "garanti", element: <GarantiBilgileri /> },
        { path: "yasal", element: <YasalZorunluluklar /> },
        { path: "about", element: <About /> },
        { path: "testvideos", element: <TestVideos /> },
        { path: "announcements", element: <Announcements /> },
        {
          path: "products",
          element: <Products />,
          /* children: [
            { path: ":id", element: <ProductDetail /> },
            // Add the following line if you want to keep "productdetails" for direct access
            { path: "productdetails", element: <ProductDetail /> },
          ],*/
        },

        {
          path: "products/:id",
          element: <ProductDetail />,
        },
        { path: "login", element: <Login /> },
        { path: "cardtest", element: <Card /> },
        { path: "share", element: user ? <SharePage /> : <h1>404</h1> },
        { path: "sharevideo", element: user ? <ShareVideo /> : <h1>404</h1> },
        {
          path: "shareannouncement",
          element: user ? <ShareAnnouncement /> : <h1>404</h1>,
        },
        {
          path: "shareabout",
          element: user ? <ShareAbout /> : <h1>404</h1>,
        },
        {
          path: "shareproduct",
          element: user ? <ShareProduct /> : <h1>404</h1>,
        },
        {
          path: "sharegaranti",
          element: user ? <ShareGaranti /> : <h1>404</h1>,
        },
        {
          path: "shareyasal",
          element: user ? <ShareYasal /> : <h1>404</h1>,
        },
        {
          path: "sharehomevideo",
          element: user ? <ShareHomeVideo /> : <h1>404</h1>,
        },
        {
          path: "imageinput",
          element: user ? <ImageInputModal /> : <h1>404</h1>,
        },
        {
          path: "editproduct",
          element: user ? <EditProduct /> : <h1>404</h1>,
        },
      ],
    },
  ]);
  return <RouterProvider router={router} />;
}

export default App;
