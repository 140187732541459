// Logo.js
import React from "react";
import logoImg from "./WhatsApp Image 2023-11-10 at 12.06.02.jpeg";

import classes from "./Logo.module.css";

const Logo = ({ sizeHeight, sizeWidth }) => {
  return (
    <div
      className={classes.logoContainer}
      style={{ width: sizeWidth, height: sizeHeight }}
    >
      <img src={logoImg} alt="logo" />
    </div>
  );
};

export default Logo;
