import { Link } from "react-router-dom";
import classes from "./ContactPage.module.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faMobile,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";

const Contact = () => {
  return (
    <div className={classes.contactPageWrapper}>
      <div className={classes.contactContainer}>
        <h1 className={classes.contactUsHeader}> Bizimle İletişime Geçin </h1>
        <h2 style={{ backgroundColor: "rgb(21,21,21)", marginTop: "1.1rem" }}>
          Bizimle dilediğiniz zaman iletişime geçebilirsiniz.
        </h2>
        <ul>
          <li>
            <h2>
              <FontAwesomeIcon
                style={{ backgroundColor: "transparent" }}
                icon={faLocationDot}
              />
              Adres
            </h2>
            <address>
              Ünalan, 2. Bostancı Sk. No:10 D:1, 64100 Merkez/Uşak, Türkiye
            </address>
            <h3 className={classes.showForm}>
              <Link to="/address">Adresimizi Görmek İçin Tıklayın</Link>
            </h3>
          </li>
          <li>
            <h2>
              <FontAwesomeIcon
                style={{ backgroundColor: "transparent" }}
                icon={faEnvelope}
              />
              &nbsp; E-mail
            </h2>
            <p>dedektoravci@gmail.com</p>
          </li>
          <li>
            <h2>
              <FontAwesomeIcon
                style={{ backgroundColor: "transparent" }}
                icon={faMobile}
              />{" "}
              Telefon Numarası
            </h2>
            <p>0(552) 711 71 64</p>

            <Link
              to="https://wa.me/+9005527117164"
              target="_blank"
              rel="noopener noreferrer"
            >
              <h3 className={classes.showForm}>
                Bizimle <span> Whatsapp </span> Üzerinden İletişime Geçmek İçin
                Tıklayın
              </h3>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Contact;
