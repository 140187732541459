import "./ShareVideo.css";

import { useState } from "react";
import usePost from "../../hooks/usePost";
import useInput from "../../hooks/useInput";
import { ref, push, getDatabase } from "firebase/database";
import { firebaseConfig } from "../login/firebase";
import { initializeApp } from "firebase/app";

const ShareVideo = () => {
  const isNotEmpty = (value) => value.trim() !== "";
  const isYoutubeLink = (value) => value.includes("youtube");
  const [formIsSubmitted, setFormIsSubmitted] = useState(false);
  const firebaseApp = initializeApp(firebaseConfig);

  const database = getDatabase(firebaseApp);

  const [titlePlaceholder, setTitlePlaceholder] = useState(
    "Videonuzun başlığını girin"
  );
  const [linkPlaceholder, setLinkPlaceholder] = useState(
    "Videonuzun linkini girin"
  );

  const {
    value: titleValue,
    isValid: titleIsValid,
    isTouched: titleIsTouched,
    hasError: titleHasError,
    valueChangeHandler: titleValueChangeHandler,
    inputBlurHandler: titleInputBlurHandler,
    reset: resetTitle,
  } = useInput(isNotEmpty);
  const {
    value: linkValue,
    isValid: linkIsValid,
    isTouched: linkIsTouched,
    hasError: linkHasError,
    valueChangeHandler: linkValueChangeHandler,
    inputBlurHandler: linkInputBlurHandler,
    reset: resetLink,
  } = useInput(isYoutubeLink);

  let formIsValid = false;

  if (titleIsValid && linkIsValid) {
    formIsValid = true;
  }

  let formIsTouched = false;

  const apiUrl =
    "https://avci-dedektor-83c42-default-rtdb.europe-west1.firebasedatabase.app/videos.json";

  const { postReq, response, isLoading } = usePost(apiUrl, {
    titleValue,
    linkValue,
  });

  const handlePost = async (e) => {
    const dataToPost = {
      titleValue,
      linkValue,
      // other data properties
    };
    e.preventDefault();

    try {
      const newPostRef = push(ref(database, "videos"), dataToPost);
      console.log("Data posted successfully with key:", newPostRef.key);
    } catch (error) {
      console.error("Error posting data:", error);
    }

    resetTitle();
    resetLink();
    setFormIsSubmitted(false);
  };

  const titleClass =
    formIsSubmitted || (titleHasError && titleIsTouched)
      ? "errorClass"
      : "input";

  const linkClass = linkHasError && linkIsTouched ? "errorClass" : "input";

  return (
    <div className="container-fluid h-120">
      <div className="row justify-content-center align-items-center h-100">
        <div className="col-12">
          <div
            className="card bg-white my-5 mx-auto"
            style={{ borderRadius: "1rem", maxWidth: "500px" }}
          >
            <div className="card-body p-5 d-flex flex-column">
              <h2 className="fw-bold mb-2 text-center">Video Paylaş</h2>

              <div className="form-group">
                <label htmlFor="title">Başlık</label>
                <input
                  onChange={titleValueChangeHandler}
                  value={titleValue}
                  onBlur={titleInputBlurHandler}
                  className={titleClass}
                  type="text"
                  size="lg"
                  placeholder={titlePlaceholder}
                />
                {titleHasError && titleIsTouched && (
                  <p className="invLinkMes">Bu Alan Boş Bırakılamaz!</p>
                )}
              </div>

              <div className="form-group">
                <label htmlFor="link">Link</label>
                <input
                  onChange={linkValueChangeHandler}
                  onBlur={linkInputBlurHandler}
                  className={linkClass}
                  type="text"
                  size="lg"
                  value={linkValue}
                  placeholder={linkPlaceholder}
                />
                {linkHasError && linkIsTouched && (
                  <p className="invLinkMes">
                    Sadece Youtube Linki Gönderilebilir ve Bu Alan Boş
                    Bırakılamaz!
                  </p>
                )}
              </div>

              <button
                onClick={handlePost}
                type="post"
                className="btn btn-lg btn-primary mb-2"
              >
                Paylaş
              </button>
              {isLoading && <p className="loading">Loading...</p>}
              {response && <p className="validMes">Video Paylaşıldı</p>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShareVideo;
