import { useState } from "react";
import classes from "./ContactModal.module.css";
import ReactDOM from "react-dom";

const Backdrop = ({ onClick }) => {
  return <div onClick={onClick} className={classes.backdrop} />;
};

const ImageInputModalOverlay = (props) => {
  const [numberOfImages, setNumberOfImages] = useState(0);
  const [pickedNumber, setPickedNumber] = useState(null);
  const [showInput, setShowInput] = useState(true);

  const inputChange = (e) => {
    setNumberOfImages(e.target.value);
  };

  const inputHandler = () => {
    setPickedNumber(numberOfImages);
    setShowInput(false);
  };

  const ImageTag = () => {
    return (
      <div className="form-group">
        <label htmlFor="link">Görsel</label>
        <br />
        <input
          className="btn btn-danger bg-green"
          type="file"
          size="lg"
          accept="image/*"
          onChange={props.onChange}
        />
      </div>
    );
  };

  const imageTags = [];

  // Use a loop to add ImageTag components based on the pickedNumber
  for (let i = 1; i <= pickedNumber; i++) {
    imageTags.push(<ImageTag key={i} /* other props if needed */ />);
  }

  return (
    <div>
      {showInput && (
        <>
          <label htmlFor="numberInput">
            Kaç adet görsel yüklemek istersiniz?
          </label>
          <br />
          <input
            type="number"
            id="numberInput"
            name="numberInput"
            min="0"
            max="10"
            onChange={inputChange}
            value={numberOfImages}
          />{" "}
          <button
            className="btn btn-lg btn-primary mb-2"
            onClick={inputHandler}
          >
            Seç
          </button>
        </>
      )}
      {imageTags}
    </div>
  );
};

const ImageInputModal = ({ onClick }) => {
  return (
    <>
      {ReactDOM.createPortal(
        <Backdrop onClick={onClick} />,
        document.getElementById("imageinputbackdrop-root")
      )}
      {ReactDOM.createPortal(
        <ImageInputModalOverlay />,
        document.getElementById("imageinputmodaloverlay-root")
      )}
    </>
  );
};

export default ImageInputModal;
