import ReactDOM from "react-dom";
import { NavLink } from "react-router-dom";
import classes from "./ContactModal.module.css";
import { motion } from "framer-motion";

const ContactElement = ({ content, to }) => {
  return <NavLink to={to}>{content} </NavLink>;
};

const Backdrop = ({ onClick }) => {
  return <div onClick={onClick} className={classes.backdrop} />;
};

const ModalOverlay = () => {
  return (
    <li>
      <motion.div
        initial={{ opacity: 0, y: -30 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0 }}
        open
        className={classes.contactWrapper}
      >
        <ContactElement to="contact" content="İletişim Bilgilerimiz" />
        <ContactElement to="garanti" content="Garanti Koşulları" />
        <ContactElement to="yasal" content="Yasal Zorunluluklar" />
      </motion.div>
    </li>
  );
};

const ContactModal = ({ onClick }) => {
  return (
    <>
      {ReactDOM.createPortal(
        <Backdrop onClick={onClick} />,
        document.getElementById("backdrop-root")
      )}
      {ReactDOM.createPortal(
        <ModalOverlay />,
        document.getElementById("modaloverlay-root")
      )}
    </>
  );
};

export default ContactModal;
