import classes from "./HomePage.module.css";
import { Product } from "./ProductsPage";
import { Announcement } from "./AnnouncementsPage";
import { useState, useEffect } from "react";

import { getDatabase, ref, set } from "firebase/database";

const API_URL =
  "https://avci-dedektor-83c42-default-rtdb.europe-west1.firebasedatabase.app/homevideos.json";

const HomePage = () => {
  //const app = initializeApp(firebaseConfig);
  //const auth = getAuth(app);
  //var user = firebase.auth().currentUser;

  /*const database = getDatabase();

  const db = getDatabase();
  set(ref(db, "cars"), {
    username: "'asd",
  });*/
  const [videos, setVideos] = useState([]);

  const fetchData = async () => {
    try {
      const response = await fetch(API_URL);

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const data = await response.json();
      const videosArray = Object.entries(data).map(([id, values]) => ({
        id,
        ...values,
      }));
      setVideos(videosArray);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const VideoTag = () => {
    return videos.map((video) => (
      <iframe
        className={classes.video}
        src={`https://www.youtube.com/embed/${
          video.linkValue?.match(/[?&]v=([^?&]+)/)?.[1]
        }`}
        title="YouTube Video"
        allowFullScreen
      />
    ));
  };

  return (
    <div className={classes.mainWrapper}>
      <div className={classes.mainContentContainer}>
        <VideoTag />
        {/*      <iframe
          className={classes.video}
          src="https://www.youtube.com/embed/KK0AIQAzFkU"
          title="YouTube Video"
          allowFullScreen
        />*/}
      </div>
      <div className={classes.productsWrapper}>
        <ul>
          <Product
            imageContainerClass={classes.customImageContainerStyles}
            titleContainerClass={classes.customTitleContainerStyles}
            priceContainerClass={classes.customPriceContainerStyles}
            showDeleteButton={false}
          />
        </ul>
      </div>
      <div className={classes.announcementsWrapper}>
        <ul>
          <Announcement
            imageContainerClass={classes.imageContainerAnnouncement}
            titleContainerClass={classes.titleContainerAnnouncement}
            priceContainerClass={classes.priceContainerAnnouncement}
            showDeleteButton={false}
          />
        </ul>
      </div>
    </div>
  );
};

export default HomePage;
