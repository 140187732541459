import classes from "./ProductsPage.module.css";
import useAuth from "../hooks/useAuth";
import { useState, useEffect } from "react";
import {
  listAll,
  ref,
  deleteObject,
  getDownloadURL,
  getStorage,
} from "firebase/storage";
import { storage } from "../components/login/firebase";
import { Link } from "react-router-dom";
import _ from "lodash";
import styled, { keyframes } from "styled-components";
import { ref as rf, set, getDatabase } from "firebase/database";
import { firebaseConfig } from "../components/login/firebase";
import { initializeApp } from "firebase/app";

const API_URL =
  "https://avci-dedektor-83c42-default-rtdb.europe-west1.firebasedatabase.app/products.json";

export const Product = ({
  imageContainerClass,
  titleContainerClass,
  priceContainerClass,
  showDeleteButton = true,
}) => {
  const firebaseApp = initializeApp(firebaseConfig);

  const database = getDatabase(firebaseApp);

  const user = useAuth();
  const [products, setProducts] = useState([]);
  const [imagesList, setImagesList] = useState([]);
  const [keyValues, setKeyValues] = useState([]);
  const [loading, setLoading] = useState(false);

  const rotate = keyframes`
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  `;
  const Spinner = styled.div`
    border: 8px solid #f3f3f3;
    border-top: 8px solid #3498db;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: ${rotate} 1s linear infinite;
  `;

  // Move the function declaration here
  const fetchImages = async () => {
    setLoading(true);

    try {
      const imageUrls = await Promise.all(
        keyValues.map(async (keyValue) => {
          const path = `productImages/${keyValue}/`;
          const imageListRef = ref(storage, path);
          const imageList = await listAll(imageListRef);

          if (imageList.items.length > 0) {
            const imageUrl = await getDownloadURL(imageList.items[0]);
            return imageUrl;
          }

          return null;
        })
      );

      setImagesList(imageUrls.filter((url) => url !== null));
    } catch (error) {
      console.error("Error fetching images:", error);
    }
    setLoading(false);
  };

  // Debounce the fetchImages function
  const debouncedFetchImages = _.debounce(fetchImages, 300);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      try {
        const response = await fetch(API_URL);

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const data = await response.json();
        const productsArray = Object.entries(data).map(([id, values]) => ({
          id,
          ...values,
        }));
        setProducts(productsArray);

        // Extract keyValues and set them in state
        const keyValueArray = productsArray.map((product) => product.keyValue);
        setKeyValues(keyValueArray);
      } catch (error) {
        console.error("Error:", error);
      }
      setLoading(false);
    };
    fetchData();
  }, []);

  useEffect(() => {
    debouncedFetchImages();
  }, [keyValues]);

  const deleteHandler = async (productId, images) => {
    try {
      // Delete the announcement from Firebase Realtime Database
      await set(rf(database, `products/${productId}`), null);
      console.log("Data deleted successfully");

      const keyValue = products.map((product) => product.keyValue);

      // Get a list of all items in the directory
      const imageRef = ref(storage, `productImages/${keyValue}`);
      const items = await listAll(imageRef);

      // Process each file and delete it
      await Promise.all(
        items.items.map(async (item) => {
          await deleteObject(item);
        })
      );

      // Update the state to remove the deleted announcement

      // Reload the page
      window.location.reload();
    } catch (error) {
      console.error("Error deleting:", error);
    }
  };

  if (loading) {
    return (
      <div style={{ position: "absolute", top: "50%", left: "50%" }}>
        <Spinner />
      </div>
    );
  }
  return products.map((product, index) => (
    <li key={product.id}>
      <Link to={`/products/${product.id}`} className={classes.Link}>
        <div className={`${classes.imageContainer} ${imageContainerClass}`}>
          {/* Use optional chaining to handle undefined product.images */}
          <img
            key={index}
            src={imagesList[index]}
            alt={`product-${index + 1}`}
          />
        </div>
        <div
          className={`${classes.titleProductContainer} ${titleContainerClass}`}
        >
          <h2>{product.titleValue}</h2>
        </div>
        {product.isDowngraded ? (
          <div className={classes.priceProductContainer}>
            <p style={{ color: "black", textDecoration: "line-through" }}>
              {product.priceValue} ₺
            </p>
            &nbsp;&nbsp;
            <p
              style={{
                color: "black",
              }}
            >
              {product.downgradedPriceValue} ₺
            </p>
          </div>
        ) : (
          <div
            className={`${classes.priceProductContainer} ${priceContainerClass}`}
          >
            <p style={{ color: "black" }}>{product.priceValue} ₺</p>
          </div>
        )}
      </Link>
      {user && showDeleteButton && (
        <button
          style={{ position: "absolute", bottom: "0" }}
          onClick={() => deleteHandler(product.id, product.images)}
          className="btn btn-danger"
        >
          Delete
        </button>
      )}
    </li>
  ));
};

const Products = () => {
  return (
    <div className={classes.productsWrapper}>
      <ul>
        <Product />
      </ul>
    </div>
  );
};

export default Products;
