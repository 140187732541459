import "./ShareVideo.css";

import { useState, useEffect } from "react";
import usePost from "../../hooks/usePost";
import useInput from "../../hooks/useInput";
import { update } from "firebase/database";
import { getDatabase, ref as dbRef } from "firebase/database";
import { firebaseConfig } from "../login/firebase";
import { initializeApp } from "firebase/app";

const firebaseApp = initializeApp(firebaseConfig);
const database = getDatabase(firebaseApp);

const ShareHomeVideo = () => {
  const isYoutubeLink = (value) => value.includes("youtube");
  const [newLink, setNewLink] = useState("");
  const productRef = dbRef(database, `homevideos/-Nkp5c_3UcjnVEiYXOII`);

  const [videos, setVideos] = useState([]);

  const handleLinkChange = (e) => {
    setNewLink(e.target.value);
  };

  const {
    value: linkValue,
    isTouched: linkIsTouched,
    hasError: linkHasError,
    valueChangeHandler: linkValueChangeHandler,
    reset: resetLink,
  } = useInput(isYoutubeLink);

  const apiUrl =
    "https://avci-dedektor-83c42-default-rtdb.europe-west1.firebasedatabase.app/homevideos.json";

  const { postReq, response, isLoading } = usePost(apiUrl, {
    linkValue,
  });
  console.log(videos.length);

  const fetchData = async () => {
    try {
      const response = await fetch(apiUrl);

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const data = await response.json();
      const videosArray = Object.entries(data).map(([id, values]) => ({
        id,
        ...values,
      }));
      setVideos(videosArray);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const isVideoExisting = videos.length === 1;
  console.log(isVideoExisting, "isvideoexist");

  console.log(videos.map((video) => video.linkValue));

  const handlePost = async (e) => {
    e.preventDefault();
    if (!isVideoExisting) {
      postReq();
      resetLink();
      console.log("postreq");
    } else {
      try {
        await update(productRef, {
          linkValue: newLink,
        });
        console.log("else");
      } catch (error) {
        // Handle the error if needed
        console.error("Error updating video:", error);
      }
      window.location.reload();
    }
  };

  const linkClass = linkHasError && linkIsTouched ? "errorClass" : "input";

  return (
    <div className="container-fluid h-120">
      <div className="row justify-content-center align-items-center h-100">
        <div className="col-12">
          <div
            className="card bg-white my-5 mx-auto"
            style={{ borderRadius: "1rem", maxWidth: "500px" }}
          >
            <div className="card-body p-5 d-flex flex-column">
              <h2 className="fw-bold mb-2 text-center">Video Paylaş</h2>

              <div className="form-group">
                <label htmlFor="link">Link</label>
                {!isVideoExisting ? (
                  <input
                    onChange={linkValueChangeHandler}
                    className={linkClass}
                    type="text"
                    size="lg"
                    value={linkValue}
                    placeholder="Videonuzun linkini girin"
                  />
                ) : (
                  <input
                    onChange={handleLinkChange}
                    className={linkClass}
                    type="text"
                    size="lg"
                    value={newLink}
                    placeholder="Videonuzun linkini girin"
                  />
                )}
              </div>

              <button
                onClick={handlePost}
                type="post"
                className="btn btn-lg btn-primary mb-2"
              >
                Paylaş
              </button>
              {isLoading && <p className="loading">Loading...</p>}
              {response && <p className="validMes">Video Paylaşıldı</p>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShareHomeVideo;
