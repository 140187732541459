import "./ShareVideo.css";
import { ref as rf, push, getDatabase } from "firebase/database";
import { firebaseConfig } from "../login/firebase";
import { initializeApp } from "firebase/app";

import usePost from "../../hooks/usePost";
import useInput from "../../hooks/useInput";

const isNotEmpty = (value) => value.trim() !== "";
const apiUrl =
  "https://avci-dedektor-83c42-default-rtdb.europe-west1.firebasedatabase.app/about.json";

const ShareAbout = () => {
  const firebaseApp = initializeApp(firebaseConfig);

  const database = getDatabase(firebaseApp);

  const {
    value: aboutValue,
    valueChangeHandler: aboutValueChangeHandler,
    reset: resetAbout,
  } = useInput(isNotEmpty);

  const { postReq, isLoading } = usePost(apiUrl, {
    aboutValue,
  });

  const handlePost = async (e) => {
    const dataToPost = {
      aboutValue,
      // other data properties
    };

    e.preventDefault();
    try {
      const newPostRef = push(rf(database, "about"), dataToPost);
      console.log("Data posted successfully with key:", newPostRef.key);
      // Handle success or update state as needed
    } catch (error) {
      console.error("Error posting data:", error);
      // Handle error as needed
    }
    resetAbout();
  };

  return (
    <div className="container-fluid h-120">
      <div className="row justify-content-center align-items-center h-100">
        <div className="col-12">
          <div
            className="card bg-white my-5 mx-auto"
            style={{ borderRadius: "1rem", maxWidth: "500px" }}
          >
            <div className="card-body p-5 d-flex flex-column">
              <h2 className="fw-bold mb-2 text-center">Hakkımızda Paylaş</h2>

              <div className="form-group">
                <label htmlFor="link">Açıklama</label>
                <textarea
                  value={aboutValue}
                  placeholder="Hakkımızda açıklamasını giriniz."
                  onChange={aboutValueChangeHandler}
                  className="input"
                  type="text"
                  size="lg"
                />
              </div>

              <button
                onClick={handlePost}
                type="post"
                className="btn btn-lg btn-primary mb-2"
              >
                Paylaş
              </button>
              {isLoading && <p className="loading">Loading...</p>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ShareAbout;
