import { createSlice } from "@reduxjs/toolkit";

const initialState = { isVisible: false };

const sliceOptions = {
  name: "click",
  initialState,
  reducers: {
    open(state) {
      state.isVisible = true;
    },
    close(state) {
      state.isVisible = false;
    },
    toggle(state) {
      state.isVisible = !state.isVisible;
    },
  },
};

const clickSlice = createSlice(sliceOptions);

export const clickActions = clickSlice.actions;

export default clickSlice;
