import classes from "./GarantiBilgiler.module.css";
import { ref, set, getDatabase } from "firebase/database";
import { firebaseConfig } from "../components/login/firebase";
import { initializeApp } from "firebase/app";

import { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import useAuth from "../hooks/useAuth";

import PComponent from "../components/PComponent/PComponent";
const API_URL =
  "https://avci-dedektor-83c42-default-rtdb.europe-west1.firebasedatabase.app/yasal.json";

const YasalZorunluluklar = () => {
  const firebaseApp = initializeApp(firebaseConfig);

  const database = getDatabase(firebaseApp);

  const DUMMY_TITTLE = "Yasal Zorunluluklar";
  const [yasalContents, setYasalContents] = useState([]);
  const [loading, setLoading] = useState(false);
  const user = useAuth();

  const rotate = keyframes`
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  `;
  const Spinner = styled.div`
    border: 8px solid #f3f3f3;
    border-top: 8px solid #3498db;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: ${rotate} 1s linear infinite;
  `;

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await fetch(API_URL);

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const data = await response.json();
      const yasalsArray = Object.entries(data).map(([id, values]) => ({
        id,
        ...values,
      }));
      setYasalContents(yasalsArray);
    } catch (error) {
      console.error("Error:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (loading) {
    return (
      <div style={{ position: "absolute", top: "50%", left: "50%" }}>
        <Spinner />
      </div>
    );
  }

  const deleteHandler = async (yasalId) => {
    /* try {
      // Delete the announcement from Firebase Realtime Database
      const apiUrl = `https://avci-dedektor-83c42-default-rtdb.europe-west1.firebasedatabase.app/yasal.json`;
      const deleteResponse = await fetch(apiUrl, { method: "DELETE" });

      if (!deleteResponse.ok) {
        const errorResponse = await deleteResponse.json(); // Assuming the response is JSON
        console.error(
          "Failed to delete announcement from database:",
          errorResponse
        );
        throw new Error("Failed to delete announcement from database");
      }

      // Delete the corresponding image from Firebase Storage

      // Update the state to remove the deleted announcement
      setYasalContents((prevYasals) =>
        prevYasals.filter((yasal) => yasal.id !== yasalId)
      );
    } catch (error) {
      console.error("Error deleting:", error);
    }*/ try {
      // Set the value to null to delete the data at the specified key
      await set(ref(database, `yasal`), null);
      console.log("Data deleted successfully");
      // Handle success or update state as needed
    } catch (error) {
      console.error("Error deleting data:", error);
      // Handle error as needed
    }

    window.location.reload();
  };

  const id = yasalContents.map((item) => item.id);
  const garanti = yasalContents.map((item) => item.yasalValue);

  return (
    <>
      <div className={classes.wrapper}>
        <PComponent
          key={id}
          classname={classes.container}
          title={DUMMY_TITTLE}
          text={garanti}
        />
      </div>{" "}
      {user && (
        <button
          className="btn btn-danger"
          style={{ justifySelf: "center" }}
          onClick={deleteHandler}
        >
          Metni Sıfırlamak İçin Tıklayın
        </button>
      )}
    </>
  );
};

export default YasalZorunluluklar;
