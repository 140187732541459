import "./ShareVideo.css";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useState } from "react";
import usePost from "../../hooks/usePost";
import useInput from "../../hooks/useInput";
import { storage } from "../login/firebase";
import { v4 } from "uuid";
import { ref as rf, push, getDatabase } from "firebase/database";
import { firebaseConfig } from "../login/firebase";
import { initializeApp } from "firebase/app";

const isNotEmpty = (value) => value.trim() !== "";

const ShareAnnouncement = () => {
  const firebaseApp = initializeApp(firebaseConfig);

  const database = getDatabase(firebaseApp);

  const [formIsSubmitted, setFormIsSubmitted] = useState(false);
  const [image, setImage] = useState(null);

  const [titlePlaceholder, setTitlePlaceholder] = useState(
    "Duyurunuzun başlığını girin"
  );

  const [desPlaceholder, setDesPlaceholder] = useState(
    "Duyurunuzun açıklamasını girin"
  );

  const apiUrl =
    "https://avci-dedektor-83c42-default-rtdb.europe-west1.firebasedatabase.app/announcements.json";

  const {
    value: titleValue,
    isValid: titleIsValid,
    isTouched: titleIsTouched,
    hasError: titleHasError,
    valueChangeHandler: titleValueChangeHandler,
    inputBlurHandler: titleInputBlurHandler,
    reset: resetTitle,
  } = useInput(isNotEmpty);

  const {
    value: desValue,
    isValid: desIsValid,
    isTouched: desIsTouched,
    hasError: desHasError,
    valueChangeHandler: desValueChangeHandler,
    inputBlurHandler: desInputBlurHandler,
    reset: resetDes,
  } = useInput(isNotEmpty);

  let formIsValid = false;

  if (titleIsValid && desIsValid) {
    formIsValid = true;
  }

  let formIsTouched = false;

  if (titleIsTouched || desIsTouched) {
    formIsTouched = true;
  }
  const keyValue = v4();

  const { postReq, response, isLoading } = usePost(apiUrl, {
    //titleValue,
    // desValue,
    // keyValue,
    image,
  });

  const imageChangeHandler = (e) => {
    setImage(e.target.files[0]);
  };

  const handlePost = async (e) => {
    const dataToPost = {
      titleValue,
      desValue,
      keyValue,
      // other data properties
    };

    e.preventDefault();
    if (!formIsValid) {
      if (titleHasError) {
        setTitlePlaceholder("Bu Alan Boş Bırakılamaz!");
        setFormIsSubmitted(true);

        return;
      } else if (desHasError) {
        setDesPlaceholder("Bu Alan Boş Bırakılamaz!");
        setFormIsSubmitted(true);

        return;
      }

      return;
    }
    if (image) {
      // Create a storage reference for the image
      const imageName = v4();

      const storageRef = ref(
        storage,
        `announcementImages/${keyValue}/` + imageName + titleValue
      );
      try {
        const newPostRef = push(rf(database, "announcements"), dataToPost);
        console.log("Data posted successfully with key:", newPostRef.key);
        // Handle success or update state as needed
      } catch (error) {
        console.error("Error posting data:", error);
        // Handle error as needed
      }

      // Upload the image
      uploadBytes(storageRef, image).then((snapshot) => {
        // Get the download URL of the uploaded image
        getDownloadURL(storageRef).then((downloadURL) => {
          // Include the image URL in your request or handle it accordingly
          const imageData = { url: downloadURL, alt: "Image Alt Text" };

          // Include the image data in your post request
          postReq({ ...imageData });
          setImage(null);
        });
      });
    } else {
      alert("hata");
    }

    resetTitle();
    resetDes();
    setFormIsSubmitted(false);
  };

  const titleClass =
    formIsSubmitted || (titleHasError && titleIsTouched)
      ? "errorClass"
      : "input";
  const desClass = desHasError && desIsTouched ? "errorClass" : "input";

  return (
    <div className="container-fluid h-120">
      <div className="row justify-content-center align-items-center h-100">
        <div className="col-12">
          <div
            className="card bg-white my-5 mx-auto"
            style={{ borderRadius: "1rem", maxWidth: "500px" }}
          >
            <div className="card-body p-5 d-flex flex-column">
              <h2 className="fw-bold mb-2 text-center">Duyuru Paylaş</h2>

              <div className="form-group">
                <label htmlFor="title">Başlık</label>
                <input
                  placeholder={titlePlaceholder}
                  value={titleValue}
                  onChange={titleValueChangeHandler}
                  className={titleClass}
                  onBlur={titleInputBlurHandler}
                  type="text"
                  size="lg"
                />
                {titleHasError && titleIsTouched && (
                  <p className="invLinkMes">Bu Alan Boş Bırakılamaz!</p>
                )}
              </div>

              <div className="form-group">
                <label htmlFor="link">Görsel</label>
                <br />
                <input
                  className="btn btn-danger bg-green"
                  onChange={imageChangeHandler}
                  type="file"
                  size="lg"
                  accept="image/*"
                />
              </div>
              <br />

              <div className="form-group">
                <label htmlFor="link">Açıklama</label>
                <textarea
                  value={desValue}
                  onBlur={desInputBlurHandler}
                  placeholder={desPlaceholder}
                  onChange={desValueChangeHandler}
                  className={desClass}
                  type="text"
                  size="lg"
                />
                {desHasError && (
                  <p className="invLinkMes">Bu Alan Boş Bırakılamaz!</p>
                )}
              </div>

              <button
                onClick={handlePost}
                type="post"
                className="btn btn-lg btn-primary mb-2"
              >
                Paylaş
              </button>
              {isLoading && <p className="loading">Loading...</p>}
              {response && <p className="validMes">Duyuru Paylaşıldı</p>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShareAnnouncement;
