import { useState } from "react";
import { auth } from "../components/login/firebase";

const usePost = (url, data) => {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(null);

  const postReq = async () => {
    setIsLoading(true);

    try {
      const user = auth.currentUser;
      const idToken = await user.getIdToken();

      const requestOptions = {
        mode: "no-cors",

        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${idToken}`,
        },
        body: JSON.stringify(data),
      };
      const res = await fetch(url, requestOptions);
      const result = await res.json();
      setResponse(result);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };
  return { postReq, response, error, isLoading };
};

export default usePost;
