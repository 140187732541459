// CardComponent.js
import React from "react";

const Card = () => {
  return (
    <div className="container mt-5 justify-content-center">
      <div className="row justify-content-center col-md-6 card">
        <div className="card-body">
          <h5 className="card-title">Beautiful Card</h5>
          <p className="card-text">
            This card looks better with enhanced styling.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Card;
