import "./ShareVideo.css";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useState } from "react";
import usePost from "../../hooks/usePost";
import useInput from "../../hooks/useInput";
import { storage } from "../login/firebase";
import { v4 } from "uuid";
import { ref as rf, push, getDatabase } from "firebase/database";
import { firebaseConfig } from "../login/firebase";
import { initializeApp } from "firebase/app";
import { useNavigate } from "react-router-dom";

const isNotEmpty = (value) => value.trim() !== "";

const ShareProduct = () => {
  const firebaseApp = initializeApp(firebaseConfig);

  const database = getDatabase(firebaseApp);

  const [imageFirst, setImageFirst] = useState([]);
  const [imageSecond, setImageSecond] = useState([]);
  const [imageThird, setImageThird] = useState([]);
  const [imageFourth, setImageFourth] = useState([]);
  const [imageFifth, setImageFifth] = useState([]);
  const [isDowngraded, setIsDowngraded] = useState(false);

  const apiUrl =
    "https://avci-dedektor-83c42-default-rtdb.europe-west1.firebasedatabase.app/products.json";
  const {
    value: titleValue,
    valueChangeHandler: titleValueChangeHandler,
    inputBlurHandler: titleInputBlurHandler,
    reset: resetTitle,
  } = useInput(isNotEmpty);

  const {
    value: priceValue,
    valueChangeHandler: priceValueChangeHandler,
    inputBlurHandler: priceInputBlurHandler,
    reset: resetPrice,
  } = useInput(isNotEmpty);

  const {
    value: downgradedPriceValue,
    valueChangeHandler: downgradedPriceValueChangeHandler,
    inputBlurHandler: downgradedPriceInputBlurHandler,
    reset: resetDowngradedPrice,
  } = useInput(isNotEmpty);

  const {
    value: desValue,
    valueChangeHandler: desValueChangeHandler,
    inputBlurHandler: desInputBlurHandler,
    reset: resetDes,
  } = useInput(isNotEmpty);

  const keyValue = v4();

  const { postReq } = usePost(apiUrl, {
    titleValue,
    desValue,
    priceValue,
    keyValue,
    downgradedPriceValue,
    isDowngraded,
  });

  const firstImageChangeHandler = (e) => {
    const selectedImages = e.target.files[0];

    setImageFirst(selectedImages);
  };
  const secondImageChangeHandler = (e) => {
    const selectedImages = e.target.files[0];

    setImageSecond(selectedImages);
  };
  const thirdImageChangeHandler = (e) => {
    const selectedImages = e.target.files[0];

    setImageThird(selectedImages);
  };
  const fourthImageChangeHandler = (e) => {
    const selectedImages = e.target.files[0];

    setImageFourth(selectedImages);
  };
  const fifthImageChangeHandler = (e) => {
    const selectedImages = e.target.files[0];

    setImageFifth(selectedImages);
  };

  const handleCheckboxChange = (event) => {
    // Update isDowngraded state based on checkbox status
    setIsDowngraded(event.target.checked);
  };
  const navigate = useNavigate();
  const images = [imageFirst, imageSecond, imageThird, imageFourth, imageFifth];
  const handlePost = async (e) => {
    e.preventDefault();

    const dataToPost = {
      titleValue,
      desValue,
      priceValue,
      keyValue,
      downgradedPriceValue,
      isDowngraded,

      // other data properties
    };

    // Check if all required fields are filled
    if (
      titleValue &&
      priceValue &&
      desValue &&
      images.every((image) => image)
    ) {
      const uploadPromises = images.map(async (image) => {
        const imageName = v4();
        const storageRef = ref(
          storage,
          `productImages/${keyValue}/` + titleValue + imageName
        );

        return uploadBytes(storageRef, image);
      });

      try {
        const snapshots = await Promise.all(uploadPromises);

        const downloadURLs = await Promise.all(
          snapshots.map((snapshot) => getDownloadURL(snapshot.ref))
        );

        const imageData = downloadURLs.map((url) => ({
          url,
          alt: "Image Alt Text",
        }));

        try {
          const newPostRef = push(rf(database, "products"), dataToPost);
          navigate("/products");
          // Handle success or update state as needed
        } catch (error) {
          console.error("Error posting data:", error);
          alert("paylaşılamadı");
          // Handle error as needed
        }

        // Include the image data along with other form data in your post request
        const postData = {
          imageData, // Include the array of image data
        };

        // Make the post request with all the form data
        postReq(postData);

        // Reset form fields
        resetTitle();
        resetDes();
        resetPrice();
        resetDowngradedPrice();
      } catch (error) {
        console.error("Error uploading images", error);
      }
    } else {
      alert("Please fill in all required fields and upload all images.");
    }
  };

  return (
    <div className="container-fluid h-120">
      <div className="row justify-content-center align-items-center h-100">
        <div className="col-12">
          <div
            className="card bg-white my-5 mx-auto"
            style={{ borderRadius: "1rem", maxWidth: "500px" }}
          >
            <div className="card-body p-5 d-flex flex-column">
              <h2 className="fw-bold mb-2 text-center">Ürün Paylaş</h2>
              <div className="form-group">
                <label htmlFor="title">Başlık</label>
                <input
                  onChange={titleValueChangeHandler}
                  onBlur={titleInputBlurHandler}
                  placeholder="Ürününüzün başlığını girin"
                  value={titleValue}
                  className="input"
                  type="text"
                  size="lg"
                />
              </div>

              <div className="form-group">
                <label htmlFor="checkbox">İndirimli mi?</label> <br />
                <input
                  value={isDowngraded}
                  onChange={handleCheckboxChange}
                  type="checkbox"
                  size="lg"
                />
              </div>

              {isDowngraded ? (
                <>
                  {" "}
                  <div className="form-group">
                    <label>Fiyat</label>
                    <input
                      value={priceValue}
                      onBlur={priceInputBlurHandler}
                      placeholder="Ürününüzün fiyatını girin"
                      onChange={priceValueChangeHandler}
                      htmlFor="price"
                      className="input"
                      type="text"
                      size="lg"
                    />
                  </div>
                  <div className="form-group">
                    <label>İndirimli Fiyat</label>
                    <input
                      value={downgradedPriceValue}
                      onBlur={downgradedPriceInputBlurHandler}
                      placeholder="Ürününüzün fiyatını girin"
                      onChange={downgradedPriceValueChangeHandler}
                      htmlFor="price"
                      className="input"
                      type="text"
                      size="lg"
                    />
                  </div>
                </>
              ) : (
                <div className="form-group">
                  <label>Fiyat</label>
                  <input
                    value={priceValue}
                    onBlur={priceInputBlurHandler}
                    placeholder="Ürününüzün fiyatını girin"
                    onChange={priceValueChangeHandler}
                    htmlFor="price"
                    className="input"
                    type="text"
                    size="lg"
                  />
                </div>
              )}

              <div className="form-group">
                <label>Görsel Ekleyin</label>
                <br />
                <input
                  onChange={firstImageChangeHandler}
                  htmlFor="Görsel Ekleyin"
                  className="btn btn-danger"
                  type="file"
                  size="lg"
                  accept="image/*"
                />
                <input
                  onChange={secondImageChangeHandler}
                  htmlFor="Görsel Ekleyin"
                  className="btn btn-danger"
                  type="file"
                  size="lg"
                  accept="image/*"
                />
                <input
                  onChange={thirdImageChangeHandler}
                  htmlFor="Görsel Ekleyin"
                  className="btn btn-danger"
                  type="file"
                  size="lg"
                  accept="image/*"
                />
                <input
                  onChange={fourthImageChangeHandler}
                  htmlFor="Görsel Ekleyin"
                  className="btn btn-danger"
                  type="file"
                  accept="image/*"
                  size="lg"
                />
                <input
                  onChange={fifthImageChangeHandler}
                  htmlFor="Görsel Ekleyin"
                  className="btn btn-danger"
                  type="file"
                  accept="image/*"
                  size="lg"
                />
              </div>

              <div className="form-group">
                <label htmlFor="link">Açıklama</label>
                <textarea
                  value={desValue}
                  onBlur={desInputBlurHandler}
                  placeholder="Ürününüzün açıklamasını girin"
                  onChange={desValueChangeHandler}
                  className="input"
                  type="text"
                  size="lg"
                />
              </div>
              <button
                onClick={handlePost}
                type="post"
                className="btn btn-lg btn-primary mb-2"
              >
                Paylaş
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShareProduct;
