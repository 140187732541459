import "./SharePage.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";

const SharePage = () => {
  return (
    <div className="shareWrapper">
      <h2>Ne paylaşmak istiyorsun?</h2>
      <Link to="/sharehomevideo" className="btn btn-danger 40-w custom-button">
        Anasayfa Videosu Düzenle
      </Link>
      <Link to="/shareproduct" className="btn btn-danger 40-w custom-button">
        Ürün Paylaş
      </Link>
      <Link to="/sharevideo" className="btn btn-danger 40-w custom-button">
        Video Paylaş
      </Link>
      <Link
        to="/shareannouncement"
        className="btn btn-danger 40-w custom-button"
      >
        Duyuru Paylaş
      </Link>
      <Link to="/shareabout" className="btn btn-danger 40-w custom-button">
        Hakkımızda Düzenle
      </Link>{" "}
      <Link to="/sharegaranti" className="btn btn-danger 40-w custom-button">
        Garanti Düzenle
      </Link>
      <Link to="/shareyasal" className="btn btn-danger 40-w custom-button">
        Yasal Düzenle
      </Link>
    </div>
  );
};

export default SharePage;
