import classes from "./Navbar.module.css";
import { NavLink } from "react-router-dom";
import logo from "./WhatsApp Image 2023-11-10 at 14.25.11.png";
import { useDispatch, useSelector } from "react-redux";
import { clickActions } from "../../store/click-slice";
import { AnimatePresence } from "framer-motion";
import ContactModal from "../contact-modal/ContactModal";
import useAuth from "../../hooks/useAuth";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import Logoff from "../login/firebase";

const Navbar = () => {
  const user = useAuth();
  const dispatch = useDispatch();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [showNavbar, setShowNavbar] = useState(false);

  const showNavbarHandler = () => {
    setShowNavbar((prev) => !prev);
  };

  useEffect(() => {
    // Update window width when the window is resized
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // Attach event listener for window resize
    window.addEventListener("resize", handleResize);

    // Remove event listener when the component is unmounted
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty dependency array means this effect runs once on mount

  const isVisible = useSelector((state) => state.click.isVisible);

  const toggleContactHandler = () => {
    dispatch(clickActions.toggle());
  };

  const SpecNavLink = ({ children, to }) => {
    return (
      <NavLink className={({ isActive }) => isActive && classes.active} to={to}>
        {children}
      </NavLink>
    );
  };
  const Bars = ({ onClick }) => {
    return (
      <AnimatePresence>
        <FontAwesomeIcon
          style={{ fontSize: "2rem" }}
          onClick={onClick}
          icon={faBars}
        />
      </AnimatePresence>
    );
  };

  return (
    <>
      {windowWidth > 1000 ? (
        <nav className={classes.navContainer}>
          <div className={classes.name}>
            <NavLink to="/">
              <img src={logo} alt="logo" />
            </NavLink>
          </div>
          <ul className={classes.navLinksContainer}>
            {user && (
              <li className={classes.navbarListShare}>
                <SpecNavLink to="/share">Paylaş</SpecNavLink>
              </li>
            )}
            {user && (
              <li className={classes.navbarListLogoff}>
                <Logoff />
              </li>
            )}
            <li
              onClick={() => window.location.reload()}
              className={classes.navbarListMain}
            >
              <SpecNavLink to="/">Ana Sayfa</SpecNavLink>{" "}
            </li>

            <li
              onClick={() => window.location.reload()}
              className={classes.navbarListProduct}
            >
              <SpecNavLink to="/products">Ürünlerimiz</SpecNavLink>{" "}
            </li>

            <li
              onClick={() => window.location.reload()}
              className={classes.navbarListTest}
            >
              <SpecNavLink to="/testvideos">Test Videoları</SpecNavLink>{" "}
            </li>

            <li
              onClick={() => window.location.reload()}
              className={classes.navbarListNews}
            >
              <SpecNavLink to="/announcements">Duyurular</SpecNavLink>{" "}
            </li>

            <li className={classes.navbarListAbout}>
              <SpecNavLink to="/about">Hakkımızda</SpecNavLink>{" "}
            </li>

            <li className={classes.navbarListButton}>
              <button
                className={classes.contactButton}
                onClick={toggleContactHandler}
              >
                Bize Ulaşın
                <AnimatePresence>
                  {isVisible && <ContactModal />}
                </AnimatePresence>
              </button>
            </li>
          </ul>
        </nav>
      ) : showNavbar ? (
        <motion.nav
          initial={{ opacity: 0, y: -30 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0 }}
          open
          className={classes.lowNavContainer}
        >
          <div className={classes.nameSmall}>
            <NavLink to="/">
              <img src={logo} alt="logo" />
            </NavLink>
          </div>

          <ul className={classes.lowWidthNavLinksContainer}>
            <Bars onClick={showNavbarHandler} />
            <li
              onClick={() => window.location.reload()}
              className={classes.lownavbarListMain}
            >
              <SpecNavLink to="/">Ana Sayfa</SpecNavLink>
            </li>
            <li
              onClick={() => window.location.reload()}
              className={classes.lownavbarListProduct}
            >
              <SpecNavLink to="/products">Ürünlerimiz</SpecNavLink>
            </li>
            <li
              onClick={() => window.location.reload()}
              className={classes.lownavbarListTest}
            >
              <SpecNavLink to="/testvideos">Test Videoları</SpecNavLink>
            </li>
            <li
              onClick={() => window.location.reload()}
              className={classes.lownavbarListNews}
            >
              <SpecNavLink to="/announcements">Duyurular</SpecNavLink>
            </li>
            <li className={classes.lownavbarListAbout}>
              <SpecNavLink to="/about">Hakkımızda</SpecNavLink>
            </li>
            <li className={classes.lownavbarListAbout}>
              <SpecNavLink to="/contact">İletişim Bilgilerimiz</SpecNavLink>
            </li>
            <li className={classes.lownavbarListAbout}>
              <SpecNavLink to="/garanti">Garanti Koşulları</SpecNavLink>
            </li>
            <li className={classes.lownavbarListAbout}>
              <SpecNavLink to="/yasal">Yasal Zorunluluklar</SpecNavLink>
            </li>
            <li className={classes.lownavbarListButton}></li>
            {user && (
              <li className={classes.lownavbarListShare}>
                <SpecNavLink to="/share">Paylaş</SpecNavLink>
              </li>
            )}
            {user && (
              <li className={classes.lownavbarListShare}>
                <Logoff />
              </li>
            )}
          </ul>
        </motion.nav>
      ) : (
        <nav
          onClick={showNavbarHandler}
          style={{ cursor: "pointer" }}
          className={classes.lowNavContainer}
        >
          <div className={classes.nameSmall}>
            <NavLink to="/">
              <img src={logo} alt="logo" />
            </NavLink>
          </div>
          <Bars />{" "}
        </nav>
      )}
    </>
  );
};

export default Navbar;
