import { Link } from "react-router-dom";
import { ref, set, getDatabase } from "firebase/database";
import { firebaseConfig } from "../components/login/firebase";
import { initializeApp } from "firebase/app";

import classes from "./AboutPage.module.css";
import Logo from "../components/logo/Logo";
import { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import useAuth from "../hooks/useAuth";

const API_URL =
  "https://avci-dedektor-83c42-default-rtdb.europe-west1.firebasedatabase.app/about.json";

const About = () => {
  const [aboutContents, setAboutContents] = useState([]);
  const [loading, setLoading] = useState(false);
  const user = useAuth();
  const firebaseApp = initializeApp(firebaseConfig);

  const database = getDatabase(firebaseApp);

  const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;
  const Spinner = styled.div`
    border: 8px solid #f3f3f3;
    border-top: 8px solid #3498db;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: ${rotate} 1s linear infinite;
  `;

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await fetch(API_URL);

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const data = await response.json();
      const aboutsArray = Object.entries(data).map(([id, values]) => ({
        id,
        ...values,
      }));
      setAboutContents(aboutsArray);
    } catch (error) {
      console.error("Error:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (loading) {
    return (
      <div style={{ position: "absolute", top: "50%", left: "50%" }}>
        <Spinner />
      </div>
    );
  }

  console.log(aboutContents);
  const deleteHandler = async (aboutId) => {
    /* try {
      // Delete the announcement from Firebase Realtime Database
      const apiUrl = `https://avci-dedektor-83c42-default-rtdb.europe-west1.firebasedatabase.app/about.json`;
      const deleteResponse = await fetch(apiUrl, { method: "DELETE" });

      if (!deleteResponse.ok) {
        const errorResponse = await deleteResponse.json(); // Assuming the response is JSON
        console.error(
          "Failed to delete announcement from database:",
          errorResponse
        );
        throw new Error("Failed to delete announcement from database");
      }

      // Delete the corresponding image from Firebase Storage

      // Update the state to remove the deleted announcement
      setAboutContents((prevAbouts) =>
        prevAbouts.filter((about) => about.id !== aboutId)
      );
    } catch (error) {
      console.error("Error deleting:", error);
    }*/ try {
      // Set the value to null to delete the data at the specified key
      await set(ref(database, `about`), null);
      console.log("Data deleted successfully");
      // Handle success or update state as needed
    } catch (error) {
      console.error("Error deleting data:", error);
      // Handle error as needed
    }
    window.location.reload();
  };

  return (
    <div className={classes.aboutWrapper}>
      <div className={classes.imgContainer}>
        <Logo />
        <div className={classes.linksContainer}>
          <ul>
            <li>
              <Link to="/contact"> İletişim Bilgilerimiz İçin Tıklayın </Link>
            </li>
            <li>
              <Link to="/address"> Adresimizi Görmek İçin Tıklayın </Link>
            </li>
          </ul>
          {user && (
            <button
              className="btn btn-danger"
              style={{ justifySelf: "center" }}
              onClick={deleteHandler}
            >
              Hakkmızda Bölümünü Sıfırlamak İçin Tıklayın
            </button>
          )}
        </div>
      </div>
      <div className={classes.textWrapper}>
        <div className={classes.titleContainer}>
          <h1>Engin Dedektör Teknolojileri</h1>
        </div>
        {aboutContents.map((item) => (
          <div key={item.id} className={classes.textContainer}>
            <p style={{ color: "aliceblue" }}> {item.aboutValue} </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default About;
