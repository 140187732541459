const PComponent = ({ text, title, classname }) => {
  return (
    <div className={classname}>
      <h1> {title} </h1>
      <p> {text} </p>
    </div>
  );
};

export default PComponent;
